import { isGroupAssignment, isGroupPresentationActivity } from '@kritik/utils/activity';
import { Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import classNames from 'classnames';
import Switch from 'components/Form/FormInputSwitch';
import PaginationControls from 'components/PaginationControls';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import Search from 'components/core/input/Search';
import Select from 'components/core/input/Select';
import { InlineInformation } from 'components/layout';
import { localize } from 'locales';
import moment from 'moment';
import { useState } from 'react';
import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { assignmentService, courseService } from 'services';

type LoaderResponse = {
  actionItems: {
    data: {
      studentId: string;
      creationId: string;
      studentFullName: string;
      issueType: string;
      submissionType: string;
      issueDate: string;
      resolved: boolean;
    }[];
    page: number;
    resultsPerPage: number;
    totalCount: number;
  };
  activity;
};
async function loader(args: LoaderFunctionArgs): Promise<LoaderResponse> {
  const url = new URL(args.request.url);
  const courseId = args.params.courseId;
  const activityId = url.searchParams.get('activityId');
  if (!Boolean(activityId) || !Boolean(courseId)) {
    throw new Response('Invalid request', { status: 400 });
  }

  const response = await courseService().listActionItems({
    courseId,
    query: url.search.toString(),
  });
  const activityResponse = await assignmentService().get({ id: activityId });

  return { actionItems: response.data, activity: activityResponse.data };
}

const issuesOptions = [
  { label: localize({ message: 'ActionItems.IssueOptions.All' }), value: 'All' },
  {
    label: localize({ message: 'ActionItems.IssueOptions.LateCreation' }),
    value: 'LateCreation',
  },
  {
    label: localize({ message: 'ActionItems.IssueOptions.LateEvaluation' }),
    value: 'LateEvaluation',
  },
  {
    label: localize({ message: 'ActionItems.IssueOptions.GradeDispute' }),
    value: 'GradeDispute',
  },
  {
    label: localize({ message: 'ActionItems.IssueOptions.FlaggedEvaluation' }),
    value: 'FlaggedEvaluation',
  },
  {
    label: localize({ message: 'ActionItems.IssueOptions.UngradedCreation' }),
    value: 'UngradedCreation',
  },
  {
    label: localize({ message: 'ActionItems.IssueOptions.PotentialPlagiarismCreation' }),
    value: 'PotentialPlagiarismCreation',
  },
  {
    label: localize({ message: 'ActionItems.IssueOptions.FlaggedCreation' }),
    value: 'FlaggedCreation',
  },
  {
    label: localize({ message: 'ActionItems.IssueOptions.PotentialMisconduct' }),
    value: 'PotentialMisconduct',
  },
];

const submissionTypeOptions = [
  { label: localize({ message: 'ActionItems.SubmissionType.All' }), value: 'All' },
  { label: localize({ message: 'ActionItems.SubmissionType.Creation' }), value: 'Creation' },
  {
    label: localize({ message: 'ActionItems.SubmissionType.Evaluation' }),
    value: 'Evaluation',
  },
];

function ActionItems() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [studentName, setStudentName] = useState('');
  const params = useParams();
  const url = new URL(window.location.href);
  const activityId = url.searchParams.get('activityId');

  const { actionItems, activity } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const handleIssueOptionChange = (e) => {
    setSearchParams((prevParams) => {
      return {
        ...Object.fromEntries(prevParams),
        issueType: e.target.value,
        page: '1',
      };
    });
  };

  const handleSubmissionTypeOptionChange = (e) => {
    setSearchParams((prevParams) => {
      return {
        ...Object.fromEntries(prevParams),
        submissionType: e.target.value,
        page: '1',
      };
    });
  };

  return (
    <div className="action-items-container">
      <div className="action-items-container-header-row">
        <span className="action-items-container-header-row-title">
          <TranslatedText i18nKey="ActionItems.PageTitle" />
        </span>
        <Button type="secondary" onClick={() => navigate(`/course/${params.courseId}/assignment/${activityId}`)}>
          <ArrowBackIcon fontSize="small" style={{ marginBottom: '-5px', marginRight: '5px' }} />
          <TranslatedText i18nKey="ActionItems.BackToActivity" />
        </Button>
      </div>
      <Divider />
      <div className="action-items-container-filter-row">
        <div className="action-items-student-search">
          <div className="action-items-search-label">
            <TranslatedText i18nKey="Search" />
          </div>
          <Search
            placeholder={localize({ message: 'ActionItems.Search.Placeholder' })}
            onEnterKey={() => {
              setSearchParams((prevParams) => {
                return {
                  ...Object.fromEntries(prevParams),
                  studentName,
                  page: '1',
                };
              });
            }}
            onSearch={(value: string) => setStudentName(value)}
            onClearSearch={() => {
              setSearchParams((prevParams) => {
                return {
                  ...Object.fromEntries(prevParams),
                  studentName: '',
                  page: '1',
                };
              });
            }}
          />
        </div>
        <div className="action-items-issue-filter">
          <Select
            label={localize({ message: 'IssueType' })}
            value={searchParams.get('issueType') || 'All'}
            onChange={handleIssueOptionChange}
            testid={'issue-type-select'}
          >
            {issuesOptions.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value} data-testid={`issue-type-${option.value}`}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div>
          <Select
            label={localize({ message: 'SubmissionType' })}
            value={searchParams.get('submissionType') || 'All'}
            onChange={handleSubmissionTypeOptionChange}
            testid={'submission-type-select'}
          >
            {submissionTypeOptions.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value} data-testid={`submission-type-${option.value}`}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div>
          <Switch
            name="resolved"
            label={localize({ message: 'ActionItems.Switch.Label' })}
            checked={searchParams.get('resolved') === 'true'}
            onChange={() => {
              setSearchParams((prevParams) => {
                return {
                  ...Object.fromEntries(prevParams),
                  resolved: searchParams.get('resolved') === 'true' ? 'false' : 'true',
                  page: '1',
                };
              });
            }}
            testId="show-resolved-issues"
          />
        </div>
      </div>
      <Divider />
      <PaginationControls
        currentPage={actionItems.page}
        resultsPerPage={actionItems.resultsPerPage}
        totalItems={actionItems.totalCount}
      />
      <table>
        <thead>
          <tr>
            <th className="actions-table-header-issue" scope="col">
              <TranslatedText i18nKey="Issue" />
            </th>
            <th className="actions-table-header-type" scope="col">
              <TranslatedText i18nKey="Type" />
            </th>
            <th className="actions-table-header-student" scope="col">
              <TranslatedText i18nKey="Student" />
            </th>
            <th className="actions-table-header-issue-date" scope="col">
              <TranslatedText i18nKey="IssueDate" />
            </th>
            <th scope="col">
              <TranslatedText i18nKey="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {actionItems.data.map((issue, index) => {
            const issueStatusClass = classNames({
              'action-items-cell-issue--open': !issue.resolved,
              'action-items-cell-issue--resolved': issue.resolved,
            });
            const issueDate = Boolean(issue.issueDate) ? moment(issue.issueDate).format('MMM DD yyy, HH:mm') : '';
            return (
              <tr
                key={index}
                onClick={() => {
                  let redirectUrl = `/course/${params.courseId}/assignment/${activityId}/creation/${issue.creationId}${isGroupAssignment(activity) || isGroupPresentationActivity(activity) ? '?groupView=true' : ''}`;
                  if (issue.issueType === 'PotentialPlagiarismCreation') {
                    redirectUrl = `/course/${params.courseId}/assignment/${activityId}/reports/similarity/${issue.creationId}`;
                  }
                  navigate(redirectUrl);
                }}
                style={{ cursor: 'pointer' }}
                data-testid={`action-item-row-${issue.creationId}-${issue.issueType}`}
              >
                <td className="action-items-cell-issue-type">
                  <TranslatedText i18nKey={ISSUE_TYPE[issue.issueType]} />
                </td>
                <td>{issue.submissionType}</td>
                <td>{issue.studentFullName}</td>
                <td>{issueDate}</td>
                <td className={issueStatusClass}>
                  {issue.resolved ? <TranslatedText i18nKey="Resolved" /> : <TranslatedText i18nKey="Open" />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {actionItems.totalCount === 0 && (
        <InlineInformation title={localize({ message: 'Activity.ActionItems.NoItems' })} />
      )}
    </div>
  );
}

const ISSUE_TYPE = {
  LateCreation: 'ActionItems.IssueOptions.LateCreation',
  LateEvaluation: 'ActionItems.IssueOptions.LateEvaluation',
  GradeDispute: 'ActionItems.IssueOptions.GradeDispute',
  FlaggedEvaluation: 'ActionItems.IssueOptions.FlaggedEvaluation',
  UngradedCreation: 'ActionItems.IssueOptions.UngradedCreation',
  PotentialPlagiarismCreation: 'ActionItems.IssueOptions.PotentialPlagiarismCreation',
  FlaggedCreation: 'ActionItems.IssueOptions.FlaggedCreation',
  PotentialMisconduct: 'ActionItems.IssueOptions.PotentialMisconduct',
} as const;

ActionItems.loader = loader;

export { ActionItems };
