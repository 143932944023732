import { Evaluation, Student } from '@kritik/types.generated';
import * as _ from 'lodash-es';
import { denormalize } from 'normalizr';
import { creationSchema } from 'schemas';
import { getCourse } from 'selectors/course';
import { getStudentFromUser } from 'selectors/student';

export const selectStudentCreation = (state: any, studentId: any) => {
  const creationId = state.creation.studentCreationMap[studentId];
  const creation = getSubmission(state, creationId);
  return creation;
};

export const selectCreationMap = (state: any) => {
  return state.creation.studentCreationMap;
};

export const selectFeedbackReceivedMap = (state: any) => {
  return state.creation.studentFeedbackReceivedMap ? state.creation.studentFeedbackReceivedMap : {};
};

export const getSubmission = (state: any, submissionId: any) => {
  return denormalize(submissionId, creationSchema, state.entities);
};

export const getSubmissions = (state: any, assignmentId: any) => {
  const submissionsList = state.assignment.submissions;
  let submissions = [];
  if (submissionsList[assignmentId] && submissionsList[assignmentId].items) {
    submissions = denormalize(submissionsList[assignmentId].items, [creationSchema], state.entities);
  }
  return submissions.filter((submission: any) => {
    return Boolean(submission);
  });
};

export function getCreationsFromEntities(state: any, activityId: any) {
  const creations = Object.values(state.entities.user_assignments);
  return creations.filter((creation) => {
    return (creation as any).assignment === activityId;
  });
}

export function getEvaluationsFromEntities(state: any, activityId: any) {
  return Object.values(state.entities.evaluations || {});
}

export function getSelectedStudentEvaluationsFromEntities(state: any) {
  return Object.values(state.entities.evaluations || {}).filter(
    (evaluation) => (evaluation as Evaluation & { student: Student }).student._id === state.selected.studentId
  );
}

export const getMySubmission = (state: any, assignmentId: any) => {
  const subs = state.entities.user_assignments;

  if (_.isEmpty(subs)) {
    return null;
  }

  const student = getStudentFromUser(state, state.selected.courseId);

  if (!student) {
    return null;
  }
  let creation;
  Object.keys(subs).map((id) => {
    const sub = getSubmission(state, id);
    if (
      sub.assignment == assignmentId &&
      ((sub.user && sub.user._id == state.user.authUser.id) || (sub.group && sub.group.members.includes(student._id)))
    ) {
      creation = sub;
    }
  });
  return creation;
};

export const getMySubmissionsToEvaluate = (state: any, assignmentId: any) => {
  const submissions = state.entities.user_assignments;
  if (_.isEmpty(submissions)) {
    return [];
  }

  const student = getStudentFromUser(state, state.selected.courseId);

  return getSubmissionsToEvaluate(state, assignmentId, student._id);
};

export const getSubmissionsToEvaluate = (state: any, assignmentId: any, studentId: any) => {
  const submissions = state.entities.user_assignments;
  if (_.isEmpty(submissions)) {
    return [];
  }

  const submissionIds = Object.keys(submissions).filter((id) => {
    if (typeof submissions[id].assignment === 'string') {
      if (submissions[id].assignment == assignmentId) {
        if (submissions[id].reviewers.indexOf(studentId) > -1) {
          return true;
        }
      }
    } else if (submissions[id].assignment._id == assignmentId) {
      if (submissions[id].reviewers.indexOf(studentId) > -1) {
        return true;
      }
    }
  });

  return denormalize(submissionIds, [creationSchema], state.entities);
};

const getCreationsToEvaluate = (state: any) => {
  return state.creationsToEvaluate.creations || [];
};

/**
 * Get all submissions from a course for prof
 */
export const getCourseSubmissions = (state: any) => {
  const subs = state.entities.user_assignments;
  if (_.isEmpty(subs)) {
    return [];
  }

  const selectedCourse = getCourse(state);
  const submissionIds = Object.keys(subs).filter((id) => {
    return subs[id].course == selectedCourse._id;
  });

  return denormalize(submissionIds, [creationSchema], state.entities);
};

export const getCreationByActivityAndUser = (state: any, activityId: any, userId: any) => {
  const creations = state.entities.user_assignments;

  const creationId = Object.keys(creations).find((id) => {
    const creation = creations[id];
    return (
      (creation.assignment._id || creation.assignment) === activityId && (creation.user._id || creation.user) === userId
    );
  });
  if (!creationId) {
    return null;
  }
  return denormalize(creationId, creationSchema, state.entities);
};

export default {
  selectStudentCreation,
  selectCreationMap,
  selectFeedbackReceivedMap,
  getSubmission,
  getSubmissions,
  getMySubmission,
  getMySubmissionsToEvaluate,
  getSubmissionsToEvaluate,
  getCreationsToEvaluate,
  getCourseSubmissions,
  getCreationByActivityAndUser,
  getCreationsFromEntities,
};
